<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-3">{{ $t('content.gdashboard') }}</h5>

              <div class="col-12 col-lg-4">
                <select v-model="shop_seleted" class="form-select profile" :onclick='selectProfile()'
                  :disabled="shopList.length === 0">
                  <option value="0" disabled selected>{{ $t("content.amnselprofile") }}</option>
                  <option v-for="(item, index) in shopList" :value="item" :key="index">
                    {{ item.nameTh }} ( {{ item.nameEn }} )
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body pt-0" v-if="shop_seleted !== '0'">
            <div class="row">
              <div class="col-lg-4 me-3">
                <label class="form-label ms-0">{{ $t("content.sdate") }}</label>
                <VueDatePicker v-model="start_date" :format="format" auto-apply :teleport="true" :max-date="new Date()"
                  :enable-time-picker="false" month-name-format="long" :clearable="false" :text-input="textInputOptions"
                  fluid />
              </div>
              <div class="col-lg-4 me-3">
                <label class="form-label ms-0">{{ $t("content.edate") }}</label>
                <VueDatePicker v-model="end_date" :format="format" auto-apply :teleport="true" :max-date="new Date()"
                  :enable-time-picker="false" month-name-format="long" :clearable="false" :text-input="textInputOptions"
                  fluid />
              </div>
              <div class="col-lg-2 me-3">
                <label class="form-label ms-0 png"></label>
                <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                  @click="loadDataInfoApi" :disabled="monthDiff() || start_date > end_date">
                  <i class="material-icons material-symbols-outlined">search</i>
                  {{ search }}
                </material-button>
              </div>
            </div>
            <div class="row">
              <div class="col-4 text-center">
                <h5 class="mt-4 mb-0">
                  <b> {{ $t("content.amnenter") }} </b>
                </h5>

                <div>
                  <h2 class="mb-0 text-info">
                    <b>
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfCustomer) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h2>
                </div>
              </div>
              <div class="col-4 text-center">
                <h5 class="mt-4 mb-0">
                  <b> {{ $t("content.numOfCancel") }} </b>
                </h5>

                <div>
                  <h2 class="mb-0 text-info">
                    <b style="color:red;">
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfCancelTicket) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h2>
                </div>
              </div>
              <div class="col-4 text-center">
                <h5 class="mt-4 mb-0">
                  <b> {{ $t("content.numOfTerminate") }} </b>
                </h5>

                <div>
                  <h2 class="mb-0 text-info">
                    <b style="color:orange;">
                      <span v-if="loading">
                        {{ formatNumberString(dataInfo.numOfTerminate) }}
                      </span>
                      <span v-else>
                        0
                      </span>
                    </b>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="shop_seleted !== '0' && loading">
      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amnqty") }}</b>
              </h5>

              <h2 class="mb-0 text-info">
                <b>
                  {{ formatNumberString(dataInfo.numOfWorkCase) }}
                </b>
              </h2>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-times" :chart="{
                  labels: [plegde, interest, addcap, reducecap, redeem],
                  datasets: {
                    label: 'Projects',
                    data: [
                      legendTimes[0].dt,
                      legendTimes[1].dt,
                      legendTimes[2].dt,
                      legendTimes[3].dt,
                      legendTimes[4].dt,
                    ],
                    backgroundColor: colors,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt1) in legendTimes" :key="pt1"
                  class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} ({{ fmNumber(i.dt) }})</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amnmoney") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-baht" :chart="{
                  labels: [plegde, interest, addcap, reducecap, redeem],
                  datasets: {
                    label: 'Projects',
                    data: [
                      legendBaht[0].dt,
                      legendBaht[1].dt,
                      legendBaht[2].dt,
                      legendBaht[3].dt,
                      legendBaht[4].dt,
                    ],
                    backgroundColor: colors,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt2) in legendBaht" :key="pt2"
                  class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} ({{ formatNumberDecimalString(i.dt) }})</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amncashflow") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amnoutcome") }} : </span>
                  <span style="color:red">
                    {{ formatDecimalString(dataInfo.payAmount) }}
                  </span>
                </b>
              </h6>
              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amnincome") }} : </span>
                  <span style="color:green">
                    {{ formatDecimalString(dataInfo.receiveAmount) }}
                  </span>
                </b>
              </h6>

              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amninterest") }} : </span>
                  <span class="text-info">
                    {{ formatDecimalString(dataInfo.sumInterest) }}
                  </span>
                </b>
              </h6>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-sum" :chart="{
                  labels: [sincome, soutcome, sinterest],
                  datasets: {
                    label: 'Projects',
                    data: [
                      legendSum[1].dt,
                      legendSum[0].dt,
                      legendSum[2].dt,
                    ],
                    backgroundColor: colors2,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt2) in legendSum" :key="pt2" class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} ({{ formatNumberDecimalString(i.dt) }})</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amnmigflow") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amnoutcome") }} : </span>
                  <span style="color:red">
                    {{ formatDecimalString(dataInfo.totalPayMigration) }}
                  </span>
                </b>
              </h6>
              <h6 class="mb-0 text-info">
                <b>
                  <span class="text-md text-dark">{{ $t("content.amnincome") }} : </span>
                  <span style="color:green">
                    {{ formatDecimalString(dataInfo.totalReceiveMigration) }}
                  </span>
                </b>
              </h6>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-mig" :chart="{
                  labels: [interest, addcap, reducecap],
                  datasets: {
                    label: 'Projects',
                    data: [
                      legendMig[0].dt,
                      legendMig[1].dt,
                      legendMig[2].dt,
                    ],
                    backgroundColor: colors,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt2) in legendMig" :key="pt2" class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} {{ formatDecimalString(i.dt) }} ({{ fmNumber(i.dt2)
                    }})</span>
                </span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- <div class="col-12 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.amndue") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 mt-2 card-body">
            <div v-if="selectInt == 'รายวัน'" class="row">
              <div v-for="(a, pt3) in dueDay" :key="pt3" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0">
                      <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                    </h4>
                    &nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="selectInt == 'รายอาทิตย์'" class="row">
              <div v-for="(a, pt4) in dueWeek" :key="pt4" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0">
                      <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                    </h4>
                    &nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="selectInt == 'รายเดือน'" class="row">
              <div v-for="(a, pt5) in dueMonth" :key="pt5" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0">
                      <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                    </h4>
                    &nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4" style="overflow-x: auto; padding-right: 1em">
              <h5 class="">{{ $t("content.amnticketduelist") }}</h5>
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t("content.amnduedate") }}</th>
                      <th>{{ $t("content.names") }}</th>
                      <th>{{ $t("content.ticketno") }}</th>
                      <th>{{ $t("content.allweight") }}</th>
                      <th>{{ $t("content.allqty") }}</th>
                      <th>{{ $t("content.allprice") }}</th>
                      <th>{{ $t("content.amninterest") }}</th>
                      <th>{{ $t("content.cmobile") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(i, history) in datatable" :key="history">
                      <td class="text-sm font-weight-normal">{{ i.no }}</td>
                      <td class="text-sm font-weight-normal">{{ i.dat }}</td>
                      <td class="text-sm font-weight-normal">{{ i.nam }}</td>
                      <td class="text-sm font-weight-normal">{{ i.new }}</td>
                      <td class="text-sm font-weight-normal">{{ i.wei }}</td>
                      <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                      <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                      <td class="text-sm font-weight-normal">{{ i.int }}</td>
                      <td class="text-sm font-weight-normal">{{ i.mob }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="col-12 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0">
                <b>{{ $t("content.gmoney") }}</b>
              </h5>
            </div>
          </div>
          <div class="p-3 mt-2 card-body">
            <div class="row mb-5">
              <div class="col-12">
                <ul id="dashboardTab" class="nav nav-tabs" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button id="first-dashboard" class="nav-link active" data-bs-toggle="tab"
                      data-bs-target="#firstDashboard" type="button" role="tab" aria-controls="firstDashboard"
                      aria-selected="true">
                      {{ $t("content.amncash") }}
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button id="second-dashboard" class="nav-link" data-bs-toggle="tab"
                      data-bs-target="#secondDashboard" type="button" role="tab" aria-controls="secondDashboard"
                      aria-selected="false">
                      {{ $t("content.amnbankacc") }}
                    </button>
                  </li>
                </ul>

                <div id="dashboardTabContent" class="tab-content">
                  <div id="firstDashboard" class="tab-pane fade show active" role="tabpanel"
                    aria-labelledby="first-dashboard">
                    <div class="row mt-3">
                      <div v-for="(a, pt6) in drawers" :key="pt6" class="col-12 text-start mb-3">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <div class="d-flex align-items-center">
                            <material-radio :id="a.id" v-model="selectDrawer" name="selectedDrawer"
                              class="radio-horizon m-0 p-0" :value="a.vl">
                              {{ a.hd }} {{ pt6 + 1 }}
                            </material-radio>
                            <span class="ms-1 mt-1 fs-14" :class="a.sc">({{ $t("content.amnbring") }} : {{
                              fmNumber(a.bf) }})
                            </span>
                          </div>
                          <div class="d-flex align-items-center">
                            <span class="mt-1 fs-14">
                              {{ $t("content.amnincome") }} :
                            </span>
                            <span class="ms-1 mt-1 fs-14" :class="a.tx">
                              {{ fmNumber(a.vs) }}
                            </span>
                            &nbsp;
                            <span class="ms-1 mt-1 fs-14">
                              / {{ $t("content.amnoutcome") }} :
                            </span>
                            <span class="ms-1 mt-1 fs-14 text-danger">
                              {{ fmNumber(a.vm) }}
                            </span>
                            <material-button color="primary" variant="outline"
                              class="btn btn-icon-only btn-rounded btn-outline-secondary ms-2 d-flex align-items-center justify-content-center"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.file')"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </div>
                        </div>

                        <div class="progress dashboard">
                          <div class="progress-bar" :class="a.bg" role="progressbar"
                            :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                            aria-valuemin="0" aria-valuemax="100">
                            {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                          </div>
                        </div>

                        <div class="d-flex justify-content-end align-items-center mb-1">
                          <span class="mt-1 fs-14"> {{ $t("content.total") }} : </span>
                          <span class="ms-1 mt-1 fs-16" :class="a.tx">
                            {{ fmNumber(a.in) }}
                          </span>
                          <span class="ms-1 mt-1 fs-16"> / {{ fmNumber(a.ou) }}</span>
                        </div>
                      </div>
                    </div>

                    <div v-if="
                      selectDrawer == 'drawer1' ||
                      selectDrawer == 'drawer2' ||
                      selectDrawer == 'drawer3'
                    " class="row mt-3 p-3">
                      <h5 class="mb-3">
                        <u>{{ $t("content.amndrawer") }}</u>
                      </h5>
                      <div v-for="(a, pt8) in detailDrawer" :key="pt8" class="col-12 text-start mb-1">
                        <div class="d-flex justify-content-between align-items-center border-top">
                          <p class="mb-1">{{ a.hd }} ({{ a.cr }})</p>
                          <div class="d-flex align-items-center">
                            <h4 class="mb-0">
                              <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                            </h4>
                            &nbsp;
                            <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="selectDrawer == 'safe1'" class="row mt-3 p-3">
                      <h5 class="mb-3">
                        <u>{{ $t("content.amnsafe") }}</u>
                      </h5>
                      <div v-for="(a, pt9) in detailSafe" :key="pt9" class="col-12 text-start mb-1">
                        <div class="d-flex justify-content-between align-items-center border-top">
                          <p class="mb-1">{{ a.hd }} ({{ a.cr }})</p>
                          <div class="d-flex align-items-center">
                            <h4 class="mb-0">
                              <b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b>
                            </h4>
                            &nbsp;
                            <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="secondDashboard" class="tab-pane fade" role="tabpanel" aria-labelledby="second-dashboard">
                    <div class="row mt-3">
                      <div v-for="(a, pt7) in banks" :key="pt7" class="col-12 text-start mb-3">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <div class="d-flex align-items-center">
                            <span class="mt-1 fs-14">{{ a.hd }} {{ pt7 + 1 }}</span>
                            <span class="ms-1 mt-1 fs-14" :class="a.sc">({{ $t("content.amnbring") }} : {{
                              fmNumber(a.bf) }})
                            </span>
                          </div>
                          <div class="d-flex align-items-center">
                            <span class="mt-1 fs-14">
                              {{ $t("content.amnincome") }} :
                            </span>
                            <span class="ms-1 mt-1 fs-14" :class="a.tx">
                              {{ fmNumber(a.vs) }}
                            </span>
                            &nbsp;
                            <span class="ms-1 mt-1 fs-14">
                              / {{ $t("content.amnoutcome") }} :
                            </span>
                            <span class="ms-1 mt-1 fs-14 text-danger">
                              {{ fmNumber(a.vm) }}
                            </span>
                            <material-button color="primary" variant="outline"
                              class="btn btn-icon-only btn-rounded btn-outline-secondary ms-2 d-flex align-items-center justify-content-center"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.file')"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </div>
                        </div>

                        <div class="progress dashboard">
                          <div class="progress-bar" :class="a.bg" role="progressbar"
                            :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                            aria-valuemin="0" aria-valuemax="100">
                            {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                          </div>
                        </div>

                        <div class="d-flex justify-content-end align-items-center mb-1">
                          <span class="mt-1 fs-14"> {{ $t("content.total") }} : </span>
                          <span class="ms-1 mt-1 fs-16" :class="a.tx">
                            {{ fmNumber(a.in) }}
                          </span>
                          <span class="ms-1 mt-1 fs-16"> / {{ fmNumber(a.ou) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, reactive, computed, inject, onBeforeMount } from 'vue'
import { useI18n } from "vue-i18n"
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"

import PieChart from "@/examples/Charts/PieChart.vue"
import moment from 'moment'
import { useDashboardStore } from '@/stores/dashboard'


const storedash = useDashboardStore()

///// variables
const { t } = useI18n()
const swal = inject('$swal')

///// data
const selectInt = ref("รายวัน")
const selectDrawer = ref("drawer1")


const shopList = ref([]);
const shop_seleted = ref();

const start_date = ref(new Date());
const end_date = ref(new Date());

const dataInfo = ref({})

const loading = ref(false)
const legendTimes = ref([]);

const legendBaht = ref([]);

const legendSum = ref([]);

const legendMig = ref([]);

const loadDataInfoApi = async () => {
  loading.value = false
  const key = JSON.parse(localStorage.getItem("shop_seleted"));
  let data = "";
  data = {
    fromDate: moment(new Date(start_date.value)).format('YYYY-MM-DDT00:00:00'),
    toDate: moment(new Date(end_date.value)).format('YYYY-MM-DDT23:59:59'),
    shopUid: key.uid
  };
  const payload = data;
  const response = await storedash.dashboardInfoFetch(payload);
  // console.log('dataInfo : ' + JSON.stringify(response.data))
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        dataInfo.value = response.data.data;
        await setnumOfTransaction()
        await setnumOfAmount()
        await setnumOfSum()
        await setnumOfMig()
        loading.value = true
        // console.log('dataInfo : ' + JSON.stringify(dataInfo.value))
      }
    }
  }
}

const loadDataList = async () => {
  // console.log("load dash : ", localStorage.getItem("shop_seleted"));
  if (localStorage.getItem("shoplist") !== null) {
    shopList.value = JSON.parse(localStorage.getItem("shoplist"));
    // console.log("shopList dash : ", shopList.value);
  }

  if (localStorage.getItem("shop_seleted") !== null) {
    shop_seleted.value = JSON.parse(localStorage.getItem("shop_seleted"));
    loadDataInfoApi()
    // console.log("shop_seleted : ", JSON.stringify(shop_seleted.value));
  } else {
    shop_seleted.value = '0'
  }
};

loadDataList();



async function selectProfile() {
  if (window.location.href.indexOf("signin") == -1) {
    if (shop_seleted.value !== null && shop_seleted.value !== '0') {
      // console.log("selectProfile dash : " + JSON.stringify(shop_seleted.value));
      if (localStorage.getItem("shop_seleted") !== null) {
        const old_shop = JSON.parse(localStorage.getItem("shop_seleted"));
        if (shop_seleted.value.uid !== old_shop.uid) {
          reloadPage()
          loadDataInfoApi()
        }
      } else {
        reloadPage()
      }
    }
  }
}

async function reloadPage() {

  // The last "domLoading" Time //

  var currentDocumentTimestamp =

    new Date(performance.timing.domLoading).getTime();

  // Current Time //

  var now = Date.now();

  // Ten Seconds //

  var twoSec = 3 * 1000;

  // Plus Ten Seconds //

  var plusTwoSec = currentDocumentTimestamp + twoSec;

  if (now > plusTwoSec) {
    const lf = filterData(shop_seleted.value.uid, shopList.value)
    // console.log("Profile dash : " + JSON.stringify(lf[0]));
    await localStorage.setItem("shop_seleted", JSON.stringify(lf[0]));
    location.reload();
  } else {
    console.log("alredy loaded");
    // shop_seleted.value = JSON.parse(localStorage.getItem("shop_seleted"));
  }

}

function filterData(str, matchArr) {
  return matchArr.filter((c) => c.uid === str);
}



const colors = reactive([
  "#809bce",
  "#95b8d1",
  "#b8e0d2",
  "#d6eadf",
  "#eac4d5",
])

const colors2 = reactive(["#dfffdd", "#fecac7", "#b8e0d2"]);




const ylabels = reactive([
  // computed(() => t("content.amnincome")),
  // computed(() => t("content.amnoutcome")),
  // computed(() => t("content.amnprofit")),

  t("content.amnincome"),
  t("content.amnoutcome"),
  t("content.amnprofit"),
])

const barColors = reactive([
  "#809bce",
  "#b8e0d2",
  "#d6eadf",
  "#eac4d5",
  "#977ECD",
  "#D0AE95",
  "#B8CCE0",
])

const bars = reactive({
  series: [
    {
      name: computed(() => t("content.actpawn1")),
      data: [0, 26, 0],
    },
    {
      name: computed(() => t("content.actpawn3")),
      data: [0, 16, 0]
    },
    {
      name: computed(() => t("content.actpawn4")),
      data: [12, 0, 0]
    },
    {
      name: computed(() => t("content.actpawn5")),
      data: [9, 0, 0]
    },
    {
      name: computed(() => t("content.amndeposit")),
      data: [25, 0, 0]
    },
    {
      name: computed(() => t("content.amnwithdraw")),
      data: [0, 10, 0]
    },
    {
      name: computed(() => t("content.amninterest")),
      data: [25, 0, 19]
    },
  ],
  chartOptions: {
    chart: {
      type: 'bar',
      height: 200,
      stacked: true,
    },
    colors: barColors,
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '14px',
              fontWeight: 900,
              colors: barColors,
            }
          }
        }
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: ylabels,
      labels: {
        formatter: function (val) {
          return val + "m"
        }
      }
    },
    yaxis: {
      type: 'String',
      title: {
        text: undefined
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "m"
        }
      }
    },
    fill: {
      opacity: 1,
      colors: barColors,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      offsetX: 40,
      fontSize: '14px',
    }
  },
})



const dueDay = reactive([
  {
    hd: computed(() => t("content.amnticketdueactived")),
    vs: 21,
    vm: 24,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amnticketdued")),
    vs: 3,
    vm: 24,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnticketoveractived")),
    vs: 4,
    vm: 20,
    tx: 'text-danger',
    bg: 'bg-danger'
  },
  {
    hd: computed(() => t("content.amnticketoverd")),
    vs: 244,
    vm: 786,
    tx: 'text-secondary',
    bg: 'bg-secondary'
  },
])

const dueWeek = reactive([
  {
    hd: computed(() => t("content.amnticketdueactivew")),
    vs: 144,
    vm: 167,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amnticketduew")),
    vs: 23,
    vm: 167,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnticketoveractivew")),
    vs: 42,
    vm: 45,
    tx: 'text-danger',
    bg: 'bg-danger'
  },
  {
    hd: computed(() => t("content.amnticketoverw")),
    vs: 244,
    vm: 786,
    tx: 'text-secondary',
    bg: 'bg-secondary'
  },
])

const dueMonth = reactive([
  {
    hd: computed(() => t("content.amnticketdueactivem")),
    vs: 807,
    vm: 851,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amnticketduem")),
    vs: 44,
    vm: 851,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnticketoveractivem")),
    vs: 423,
    vm: 425,
    tx: 'text-danger',
    bg: 'bg-danger'
  },
  {
    hd: computed(() => t("content.amnticketoverm")),
    vs: 879,
    vm: 1456,
    tx: 'text-secondary',
    bg: 'bg-secondary'
  },
])

const datatable = reactive([
  {
    no: "1",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "จำนำ",
    ass: "ทองคำ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    mob: "xxx-xxx-xxxx",
  },
  {
    no: "2",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "ต่อดอก",
    ass: "เพชร",
    wei: "12.34",
    qty: "2",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    mob: "xxx-xxx-xxxx",
  },
  {
    no: "3",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "จำนำ",
    ass: "อื่นๆ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    mob: "xxx-xxx-xxxx",
  },
  {
    no: "4",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "ไถ่ถอน",
    ass: "ทองคำ",
    wei: "15.63",
    qty: "1",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    mob: "xxx-xxx-xxxx",
  },
])

const drawers = reactive([
  {
    hd: computed(() => t("content.amndrawer")),
    id: 'sDrawer1',
    vl: 'drawer1',
    vs: 500000.00,
    vm: 2000000.00,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amndrawer")),
    id: 'sDrawer2',
    vl: 'drawer2',
    vs: 700000.00,
    vm: 2000000.00,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amndrawer")),
    id: 'sDrawer3',
    vl: 'drawer3',
    vs: 1700000.00,
    vm: 2000000.00,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amnsafe")),
    id: 'sSafe1',
    vl: 'safe1',
    vs: 6000000.00,
    vm: 24000000.00,
    tx: 'text-info',
    bg: 'bg-info'
  },
])

const banks = reactive([
  {
    hd: computed(() => t("content.amnbankacc")),
    vs: 1500000.00,
    vm: 2000000.00,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnbankacc")),
    vs: 1700000.00,
    vm: 2000000.00,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnbankacc")),
    vs: 900000.00,
    vm: 2000000.00,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
])

const detailDrawer = reactive([
  {
    hd: "1000",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "500",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "100",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "50",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "20",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "10",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "5",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "1",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: computed(() => t("content.amntotaltransact")),
    cr: computed(() => t("content.amntotbaht")),
    vs: 1400000,
    vm: 2000000,
    tx: 'text-primary',
  },
])

const detailSafe = reactive([
  {
    hd: "1000",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "500",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "100",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "50",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "20",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "10",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "5",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "1",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: computed(() => t("content.amntotaltransact")),
    cr: computed(() => t("content.amntotbaht")),
    vs: 1800000,
    vm: 2000000,
    tx: 'text-info',
  },
])

///// computed
const plegde = computed(() => t("content.actpawn1"))
const interest = computed(() => t("content.actpawn2"))
const addcap = computed(() => t("content.actpawn3"))
const reducecap = computed(() => t("content.actpawn4"))
const redeem = computed(() => t("content.actpawn5"))


///// methods
const calPercent = (val, full) => {
  return (parseInt(val) * 100) / parseInt(full)
}

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val)
}

const format = (date) => {
  return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
  format: 'dd/MM/yyyy'
};






function monthDiff() {
  let pro = false
  var months;
  months = (end_date.value.getFullYear() - start_date.value.getFullYear()) * 12;
  months += end_date.value.getMonth() - start_date.value.getMonth();

  if (months > 3) {
    pro = true
  }
  return pro
}

async function setnumOfTransaction() {
  legendTimes.value = [
    {
      bg: "bg-pledge",
      hd: computed(() => t("content.actpawn1")),
      dt: Number(dataInfo.value.numOfPawn),
    },
    {
      bg: "bg-interest",
      hd: computed(() => t("content.actpawn2")),
      dt: Number(dataInfo.value.numOfInterest),
    },
    {
      bg: "bg-add-cap",
      hd: computed(() => t("content.actpawn3")),
      dt: Number(dataInfo.value.numOfInc),
    },
    {
      bg: "bg-reduce-cap",
      hd: computed(() => t("content.actpawn4")),
      dt: Number(dataInfo.value.numOfDec),
    },
    {
      bg: "bg-redeem",
      hd: computed(() => t("content.actpawn5")),
      dt: Number(dataInfo.value.numOfRedeem),
    },
  ];


}

async function setnumOfAmount() {
  legendBaht.value = [
    {
      bg: "bg-pledge",
      hd: computed(() => t("content.actpawn1")),
      dt: Number(dataInfo.value.pawnAmount)
    },
    {
      bg: "bg-interest",
      hd: computed(() => t("content.actpawn2")),
      dt: Number(dataInfo.value.interestAmount),
    },
    {
      bg: "bg-add-cap",
      hd: computed(() => t("content.actpawn3")),
      dt: Number(dataInfo.value.incAmount),
    },
    {
      bg: "bg-reduce-cap",
      hd: computed(() => t("content.actpawn4")),
      dt: Number(dataInfo.value.decAmount),
    },
    {
      bg: "bg-redeem",
      hd: computed(() => t("content.actpawn5")),
      dt: Number(dataInfo.value.redeemAmount),
    },
  ];
}


async function setnumOfSum() {

  legendSum.value = [
    {
      bg: "bg-payment",
      hd: computed(() => t("content.amnoutcome")),
      dt: Number(dataInfo.value.pawnAmount) + Number(dataInfo.value.incAmount)
    },
    {
      bg: "bg-receive",
      hd: computed(() => t("content.amnincome")),
      dt: Number(dataInfo.value.decAmount) + Number(dataInfo.value.redeemAmount) + Number(dataInfo.value.interestAmount)
    },
    {
      bg: "bg-interest",
      hd: computed(() => t("content.amninterest")),
      dt: Number(dataInfo.value.sumInterest),
    },

  ];

}

async function setnumOfMig() {

  legendMig.value = [
    {
      bg: "bg-interest",
      hd: computed(() => t("content.actpawn2")),
      dt: Number(dataInfo.value.interestAmountMigration),
      dt2: Number(dataInfo.value.numOfInterestMigration),
    },
    {
      bg: "bg-add-cap",
      hd: computed(() => t("content.actpawn3")),
      dt: Number(dataInfo.value.incAmountMigration),
      dt2: Number(dataInfo.value.numOfIncMigration),
    },
    {
      bg: "bg-reduce-cap",
      hd: computed(() => t("content.actpawn4")),
      dt: Number(dataInfo.value.decAmountMigration),
      dt2: Number(dataInfo.value.numOfDecMigration),
    },

  ];

}

function formatNumberDecimalString(num) {
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatDecimalString(num) {
  num = Number(num)
  if (num !== null && num !== undefined) {
    num = num.toFixed(2)
  } else {
    num = 0
    num = num.toFixed(2)
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function formatNumberString(num) {
  num = Number(num)
  if (num === null || num === undefined) {
    num = 0
  }
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );

}




</script>

<style lang="scss" scoped></style>