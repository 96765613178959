import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useInterestStore = defineStore("interest", {
  actions: {
    async interestListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          shopUid: payload.shopUid,
          intType: payload.intType,
          intStatus: payload.intStatus,
          "startDate:ge": payload.startDate,
          "endDate:le": payload.endDate,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/interest/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/interest/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestAddFetch(payload) {
      const body = {
        shopUid: payload.shopUid,
        intName: payload.intName,
        description: payload.description,
        intType: payload.intType,
        defaultInt: payload.defaultInt,
        startDate: payload.startDate,
        endDate: payload.endDate,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/interest/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        shopUid: payload.shopUid,
        intName: payload.intName,
        description: payload.description,
        intType: payload.intType,
        defaultInt: payload.defaultInt,
        startDate: payload.startDate,
        endDate: payload.endDate,
        tiers: null,
        intStatus: payload.intStatus,
        version: payload.version,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/setting/interest/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestApproveFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/interest/approve/" + payload.uid;
          const response = http.put(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestRevertFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/interest/revert/" + payload.uid;
          const response = http.put(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestRevokeFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/interest/revoke/" + payload.uid;
          const response = http.put(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/interest-svc/setting/interest/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async listCustomerInterestFetch() {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/interest/customer-interest";
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async interestCalculateFetch(payload) {
      const body = {
        pawnDate: payload.pawnDate,
        previousTxDate: payload.previousTxDate,
        postponeDate: payload.postponeDate,
        pawnValue: payload.pawnValue,
        promotionUid: payload.promotionUid,
        interestUid: payload.interestUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/interest-svc/interest/calculate";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
