<template>
  <div class="custom-tree">
    <img :src=Folder_Parent alt="folder" v-if="model.children.length >= 1" height="20" width="20" class="on-tree ms-1" />
    <img :src=Folder_Child alt="folder" v-if="model.children.length == 0" height="20" width="20" class="on-tree ms-1" />
    &nbsp;&nbsp;
    <b>{{ model.name }}</b>
    <material-button variant="outline" class="on-tree ms-1" size="small" data-bs-toggle="tooltip" data-bs-placement="top"
      :title="btnAdd" :disabled="model.level >= 6 || model.children.length >= 100" @click="openAddLv1(model)">
      <i class="material-icons material-symbols-outlined">add</i>
    </material-button>
    <material-button v-show="model.level >= 1" variant="outline" class="on-tree ms-1" size="small"
      data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit" @click="openEdit(model)">
      <i class="material-icons material-symbols-outlined">edit</i>
    </material-button>
    <material-button v-show="model.level >= 1 && model.children.length === 0" variant="outline" class="on-tree ms-1"
      size="small" data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDelete" @click="showAlertdelete(model)">
      <i class="material-icons material-symbols-outlined">delete</i>
    </material-button>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { ref, defineProps, toRefs, computed, defineEmits, inject } from "vue";
import { useI18n } from "vue-i18n";
import MaterialButton from "@/components/MaterialButton.vue";
import Folder_Parent from "@/assets/img/folders-parent.svg";
import Folder_Child from "@/assets/img/folders-child.svg";


const { t } = useI18n();
const swal = inject('$swal')

const props = defineProps({
  model: {
    type: Object,
    default: () => { },
    required: true,
  },
});
const { model } = toRefs(props);
const isOpen = ref(false);
const isEdit = ref(false);
const isAddLv0 = ref(false);
const isAddLv1 = ref(false);
const isOpenSub = ref(false);

const btnAdd = computed(() => t("buttons.addd"));
const btnEdit = computed(() => t("buttons.edit"));
const btnDelete = computed(() => t("buttons.dele"));
const btnSave = computed(() => t("buttons.save"));
const btnCancel = computed(() => t("buttons.canc"));

const isFolder = computed(() => {
  return props.model.children && props.model.children.length;
});

const isFolderSub = computed(() => {
  return props.model.children && props.model.children.length;
});

const emit = defineEmits(["change", "switch", "add", "fix", "edit"]);

const newName = ref();

function toggle() {
  emit("switch", (isOpen.value = !isOpen.value));
}


function toggleSub() {
  isOpenSub.value = !isOpenSub.value
  // emit("switch", (isOpen.value = !isOpen.value));
}
function openEdit(models) {
  // emit("fix", (isEdit.value = !isEdit.value));
  isEdit.value = !isEdit.value
  showAlertEdit(models)

}
function openAddLv0(models) {
  isAddLv0.value = !isAddLv0.value
  showAlertAdd(models)
}
function openAddLv1(models) {
  isAddLv1.value = !isAddLv1.value
  console.log('openAdd Lv1 : ' + JSON.stringify(models))
  showAlertAdd(models)

}
function changeType() {
  if (!isFolder.value) {
    /* eslint-disable */
    emit("change", (props.model.children = []));
    addChild();
    emit("switch", (isOpen.value = true));
  }
}
function handleInput() {
  /* eslint-disable */
  emit("add", props.model.name.children);
}
function editChild(models) {
  /* eslint-disable */
  // emit("edit", props.model.children.push({ name: props.model.name.children }));
  const node = models
  node.newName = newName.value
  emit("edit", node);
  newName.value = ''
}
function addChild(models) {
  /* eslint-disable */
  // emit("change", props.model.children.push({ name: props.model.name.children }));
  const node = models
  node.newName = newName.value
  emit("add", node);
  // emit("change", node);
  newName.value = ''

}

function delChild(models) {
  /* eslint-disable */
  // emit("change", props.model.children.push({ name: props.model.name.children }));
  const node = models
  emit("change", node);

}

async function showAlertAdd(item) {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    input: "text",
    inputLabel: "กรุณาพิมพ์ชื่อ ",
    inputAttributes: {
      "size": "50",
      "autocomplete": "off"
    },
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value) {
          newName.value = value
          addChild(item)
          resolve();
        } else {
          resolve("กรุณาพิมพ์ชื่อ :)");
        }
      });
    }
  });
}

async function showAlertEdit(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    input: "text",
    inputLabel: "กรุณาพิมพ์ชื่อ ",
    inputAttributes: {
      "size": "50",
      "autocomplete": "off"
    },
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value) {
          newName.value = value
          editChild(item)
          resolve();
        } else {
          resolve("กรุณาพิมพ์ชื่อ :)");
        }
      });
    }
  });
}

async function showAlertdelete(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      delChild(item)
    }
  });
}
</script>

<style lang="scss" scoped>
.custom-tree {
  .btn,
  .btn:hover {
    border: none !important;
    padding: 0 0.25rem !important;
  }
}
</style>
