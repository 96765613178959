<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t('content.amntypecustomer') }}</h5>
                <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems"
                    :disabled="totalRows >= 30">
                    <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                  </material-button>

                </div>
              </div>
            </div>

          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 70px;">#</th>
                      <th>{{ $t('content.typecustomername') }}</th>
                      <th>{{ $t('content.amncredit') }}</th>
                      <th>{{ $t('content.amntypecustomer') }}</th>
                      <th style="width: 100px;">{{ $t('content.act') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal" colspan="1">
                        <material-input v-model="filterText" type="text" class="mb-0"
                          :label="$t('content.amntypecustomer')" />
                      </td>
                      <td class="text-sm font-weight-normal">
                      </td>
                      <td class="text-sm font-weight-normal">
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch" @click="loadDataApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr> -->

                    <tr v-if="addNew">
                      <td class="text-sm font-weight-normal">#</td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="newname" type="text" class="mb-0" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <InputNumber v-model="newlimit" :minFractionDigits="0" :maxFractionDigits="2" class="mb-0"
                          autocomplete="off" @focus="$event.target.select()" />
                      </td>

                      <td class="text-sm font-weight-normal">
                        <select class="form-select" v-model="typeC" v-if="addnewtype === ''">
                          <option value="" disabled selected>{{ $t('content.select') }}</option>
                          <option v-for="(item, index) in typeCategory" :value="item.items" :key="index">
                            {{ item.items }}
                          </option>
                        </select>
                        <select class="form-select" v-model="typeC" v-else-if="addnewtype === 'new'">
                          <option value="" disabled selected>{{ $t('content.select') }}</option>
                          <option value="NEW" selected>{{ typeCategory[0].items }}</option>
                        </select>
                        <select class="form-select" v-model="typeC" v-else-if="addnewtype === 'default'">
                          <option value="" disabled selected>{{ $t('content.select') }}</option>
                          <option value="DEFAULT" selected>{{ typeCategory[1].items }}</option>
                        </select>
                      </td>

                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="newname.trim() === '' || newlimit === '' || newlimit === null || typeC === ''"
                          @click="saveNewItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelSaveNewItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-for="(row, index) in list" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-input v-model="newname" type="text" class="mb-0" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <InputNumber v-model="newlimit" :minFractionDigits="0" :maxFractionDigits="2" class="mb-0"
                          autocomplete="off" @focus="$event.target.select()" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <select class="form-select" v-model="typeC" :disabled="typeC === 'NEW' || typeC === 'DEFAULT'">
                          <option value="" disabled selected>{{ $t('content.select') }}</option>
                          <option v-for="(item, index) in typeCategory" :value="item.items" :key="index">
                            {{ item.items }}
                          </option>
                        </select>
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="newname.trim() === '' || newlimit === '' || newlimit === null || typeC === ''"
                          @click="saveEditRowItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelEditRowItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>

                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ row.name }}
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ formatNumberString(row.creditLimit) }}
                      </td>

                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ row.typeCategory }}

                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="btnEdit" @click="editRowItems(row)">
                          <i class="material-icons material-symbols-outlined">edit</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel" @click="deleteRowItems(row)">
                          <i class="material-icons material-symbols-outlined">delete</i>
                        </material-button>
                      </td>

                    </tr>
                    <tr v-if="list.length === 0">
                      <td></td>
                      <td colspan="3" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>

                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
                <ul v-show="list.length > 0" class="pagination justify-content-end">
                  <div class="project-container">
                    <div class="example-one">
                      <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                        :on-click="onClickHandler" />
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { inject, computed, ref } from 'vue'
import { useI18n } from "vue-i18n"

import InputNumber from 'primevue/inputnumber';
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import { useCustomerTypeStore } from '@/stores/customertype'

const storec = useCustomerTypeStore()
const { t } = useI18n()
const swal = inject('$swal')

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))


const filterText = ref("");
const list = ref([]);
const newname = ref("");
const newlimit = ref("");
const uid = ref("");
const version = ref("");
const addNew = ref(false);
const rowEdit = ref(false);
const addnewtype = ref("");


const onClickHandler = () => {
  updatePagination()
};
const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(30);
const totalRows_s = ref();
const key = JSON.parse(localStorage.getItem("shop_seleted"));

const typeC = ref('');
const typeCategory = ref([
  { items: "NEW" },
  { items: "DEFAULT" },
  { items: "NORMAL" }
]);



const loadDataApi = async (val) => {
  clearItems();
  if (currentPage.value !== 1) {
    if (list.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }

  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: filterText.value,
    shopUid: key.uid,

  };

  const payload = data;
  const response = await storec.customertypeListFetch(payload);
  // console.log('loadDataApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        // console.log('list : '+list.value.length)
      }
    }

  }


}

loadDataApi();

async function updatePagination() {
  list.value = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: filterText.value,
    shopUid: key.uid,

  };
  const payload = data;
  const response = await storec.customertypeListFetch(payload)
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}


function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}


async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
      addNew.value = true;
      rowEdit.value = false;
      if (list.value.length > 0) {
        let ln = filterTypeNew()
        if (ln.length === 0) {
          addnewtype.value = 'new'
        } else {
          let ln = filterTypeDefault()
          if (ln.length === 0) {
            addnewtype.value = 'default'
          }
        }
      } else {
        addnewtype.value = 'new'
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      addNew.value = false;
      clearItems();
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addNew.value = false;
      addCusType();
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      addNew.value = false;
      clearItems();
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addNew.value = false;
      clearItems();
    } else if (result.dismiss == 'cancel') {
      addNew.value = true;
    }
  })
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
      rowEdit.value = true;
      uid.value = item.uid;
      newname.value = item.name;
      newlimit.value = item.creditLimit;
      version.value = item.version;
      typeC.value = item.typeCategory
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      rowEdit.value = false;
      clearItems();
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      rowEdit.value = false;
      if (uid.value !== "" && version.value !== "") {
        updatecustomertype()
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      rowEdit.value = false;
      clearItems();
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
    } else if (result.dismiss == 'cancel') {
      rowEdit.value = true;
    }
  })
}


async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deletecustomertype(item.uid);
      }
    }
  });
}


async function addCusType() {
  let data = "";
  data = {
    name: newname.value,
    creditLimit: parseFloat(newlimit.value).toFixed(2),
    typeCategory: typeC.value,
    shopUid: key.uid,
  };
  const payload = data;
  const response = await storec.customertypeAddFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('add');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updatecustomertype() {
  let data = "";
  data = {
    uid: uid.value,
    name: newname.value,
    creditLimit: parseFloat(newlimit.value).toFixed(2),
    typeCategory: typeC.value,
    shopUid: key.uid,
    version: version.value,
  };
  const payload = data;
  // console.log('update payload : ' + JSON.stringify(payload));
  const response = await storec.customertypeUpdateFetch(payload)
  // console.log('updatecustomertype : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function deletecustomertype(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;

  const response = await storec.customertypeDeleteFetch(payload);
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function clearItems() {
  addNew.value = false;
  rowEdit.value = false;
  uid.value = "";
  newname.value = "";
  newlimit.value = "";
  version.value = "";
  typeC.value = "";
  addnewtype.value = "";
}


function filterTypeNew() {
  return list.value.filter((c) => c.typeCategory === 'NEW');
}

function filterTypeDefault() {
  return list.value.filter((c) => c.typeCategory === 'DEFAULT');
}


</script>

<style lang="scss" scoped></style>