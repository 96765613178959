<template>
	<div class="container-fluid">
		<div class="row mb-5">
			<div class="col-12">
				<ul id="masterInfoAssets" class="nav nav-tabs" role="tablist">
					<li class="nav-item" role="presentation">
						<button id="interest-tab" class="nav-link active" data-bs-toggle="tab"
							data-bs-target="#interestTab" type="button" role="tab" aria-controls="interestTab"
							aria-selected="true" @click="onClickTab('1')">
							{{ $t("content.amninterest") }}
						</button>
					</li>

					<li class="nav-item" role="presentation">
						<button id="promo-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#promoTab"
							type="button" role="tab" aria-controls="promoTab" aria-selected="false"
							@click="onClickTab('2')">
							{{ $t("content.promo") }}
						</button>
					</li>
				</ul>

				<div id="interestContent" class="tab-content">
					<div id="interestTab" class="tab-pane fade show active" role="tabpanel"
						aria-labelledby="interest-tab">

						<div class="card h-100 mt-2">

							<div class="card-header d-flex justify-content-between align-items-center mb-0">
								<h5 style="display: inline-block;">{{ $t("content.amninterest") }}</h5>
								<material-button color="primary" variant="outline" class="btn ms-1"
									@click="addItemInterest">
									<i class="material-icons material-symbols-outlined">add</i>
									{{ add }}
								</material-button>

								<material-button color="primary" variant="outline" class="btn ms-1" v-show="false"
									data-bs-toggle="modal" data-bs-target="#newInterestListModal" id="newInterest">
								</material-button>

							</div>

							<div class="card-body pt-0">
								<div class="row mt-1">
									<div class="col-lg-3">
										<label class="form-label ms-0">{{ $t("content.inttypes") }}</label>
										<select class="form-select" v-model="search_int">
											<option value="">{{ $t('content.optall') }}</option>
											<option v-for="(item, index) in forms.listType" :value="item.val"
												:key="index">
												{{ item.items }}
											</option>
										</select>
									</div>

									<div class="col-lg-3">
										<label class="form-label ms-0">{{ $t("content.status") }}</label>
										<select class="form-select" v-model="search_status">
											<option value="">{{ $t('content.optall') }}</option>
											<option v-for="(item, index) in forms.listStatus" :value="item.val"
												:key="index">
												{{ item.val }}
											</option>
										</select>
									</div>

									<div class="col-lg-3">
										<label class="form-label ms-0"> {{ $t("content.amnpastsearch") }}</label>
										<select class="form-select" v-model="selectm_i">
											<option value="0" disabled selected>{{ $t('content.select') }}</option>
											<option v-for="i in 12" :key="i" :value=i>
												{{ i }} {{ $t('content.months') }}
											</option>
										</select>
									</div>





									<div class="col-lg-2">
										<label class="form-label ms-0 png"></label>
										<material-button color="primary" class="float-lg-start float-sm-end mb-3"
											@click="loadInterestListApi">
											<i class="material-icons material-symbols-outlined">search</i> {{ btnSearch
											}}
										</material-button>
									</div>
								</div>
								<div class="row">
									<div class="table-responsive">
										<table class="table table-flush">
											<thead class="thead-light">
												<tr>
													<th>#</th>
													<th>{{ $t("content.cdefault") }}</th>
													<th>{{ $t("content.listname") }}</th>
													<th>{{ $t("content.inttypes") }}</th>
													<th>{{ $t("content.startprice") }}</th>
													<th>{{ $t("content.endprice") }}</th>
													<th>{{ $t("content.cintrate") }}</th>
													<th>{{ $t("content.sdate") }}</th>
													<th>{{ $t("content.edate") }}</th>
													<th>{{ $t("content.status") }}</th>
													<th>{{ $t("content.act") }}</th>
												</tr>
											</thead>
											<tbody v-for="(row, index) in listinterest" :key="index"
												:data-group-index="index">
												<tr class="group-id-ticket">
													<td class="text-sm font-weight-normal">{{ index + 1 }}</td>
													<td class="text-sm font-weight-normal">
														<!-- <material-radio class="m-0" :value="row.defaultInt"
															:checked="row.defaultInt" /> -->
														<material-checkbox :checked="row.defaultInt" disabled />
													</td>
													<td class="text-sm font-weight-normal">{{ row.intName }}</td>
													<td class="text-sm font-weight-normal">{{ checkIntType(row.intType)
														}}
													</td>
													<td class="text-sm font-weight-normal"></td>
													<td class="text-sm font-weight-normal"></td>
													<td class="text-sm font-weight-normal"></td>

													<td class=" text-sm font-weight-normal">
														{{ format_date(row.startDate) }}</td>
													<td class=" text-sm font-weight-normal">
														{{ format_date(row.endDate) }}</td>
													<td class=" text-sm font-weight-normal">
														{{ row.intStatus }}</td>
													<td class="text-sm font-weight-normal">

														<material-button color="danger" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="terminate"
															@click="updateItemsInterest(row, 'terminate')"
															:disabled="row.intStatus !== 'APPROVE' || new Date(row.endDate) < new Date()">
															<i
																class="material-icons material-symbols-outlined">close</i>
														</material-button>
														<material-button color="warning" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="approve + '/' + revert"
															@click="updateItemsInterest(row, 'approve')"
															:disabled="(row.intStatus !== 'APPROVE' && row.intStatus !== 'DRAFT') || new Date(row.endDate) < new Date()">
															<i
																class="material-icons material-symbols-outlined">done_all</i>
														</material-button>
														<material-button color="primary" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnEdit"
															@click="editItemInterest(row)"
															:disabled="row.intStatus === 'APPROVE'">
															<i class="material-icons material-symbols-outlined">edit</i>
														</material-button>
														<material-button color="danger" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnDel"
															@click="deleteRowInterestItems(row)"
															:disabled="row.intStatus === 'APPROVE'">
															<i
																class="material-icons material-symbols-outlined">delete</i>
														</material-button>
													</td>
												</tr>
												<tr v-for="(j, set1) in row.tiers" :key="set1">
													<td class="text-sm font-weight-normal">
													</td>
													<td class="text-sm font-weight-normal"></td>
													<td class="text-sm font-weight-normal"></td>
													<td class="text-sm font-weight-normal"></td>
													<td v-if="j.uid !== '0' && j.uid !== tier_detail.uid"
														class="text-sm font-weight-normal text-end">
														{{ numberWithCommas(j.startValue) }}</td>
													<td v-if="j.uid !== '0' && j.uid !== tier_detail.uid"
														class="text-sm font-weight-normal text-end">
														{{ numberWithCommas(j.endValue) }}</td>
													<td v-if="j.uid !== '0' && j.uid !== tier_detail.uid"
														class="text-sm font-weight-normal text-end">
														{{ formatNumberString(j.rate) }}
													</td>
													<td v-if="j.uid !== '0' && j.uid !== tier_detail.uid"
														class="text-sm font-weight-normal">

														<material-button color="info" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnEdit"
															v-if="set1 + 1 === Number(row.tiers.length - 1) && row.intStatus === 'DRAFT'"
															@click="editRowItems(j)">
															<i class="material-icons material-symbols-outlined">edit</i>
														</material-button>
														<material-button color="danger" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnDel"
															v-if="set1 + 1 === Number(row.tiers.length - 1) && set1 + 1 !== 1 && row.intStatus === 'DRAFT'"
															@click="deleteRowItems(j)">
															<i
																class="material-icons material-symbols-outlined">delete</i>
														</material-button>
													</td>
													<td v-if="j.uid !== '0' && j.uid === tier_detail.uid"
														class="text-sm font-weight-normal text-end">
														<InputNumber v-model="tier_detail.startValue" autocomplete="off"
															@focus="$event.target.select()" disabled />
													</td>
													<td v-if="j.uid !== '0' && j.uid === tier_detail.uid"
														class="text-sm font-weight-normal text-end">
														<InputNumber v-model="tier_detail.endValue" autocomplete="off"
															@focus="$event.target.select()" />
													</td>
													<td v-if="j.uid !== '0' && j.uid === tier_detail.uid"
														class="text-sm font-weight-normal text-end">
														<InputNumber v-model="tier_detail.rate" :minFractionDigits="0"
															:maxFractionDigits="2" autocomplete="off"
															@focus="$event.target.select()" />
													</td>
													<td v-if="j.uid !== '0' && j.uid === tier_detail.uid"
														class="text-sm font-weight-normal">

														<material-button color="primary" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnSave" :disabled="tier_detail.startValue === '' || tier_detail.startValue === null || tier_detail.startValue <= start_limit || parseFloat(tier_detail.startValue) === 0 ||
																tier_detail.endValue === '' || tier_detail.endValue === null || tier_detail.endValue <= tier_detail.startValue || parseFloat(tier_detail.endValue) === 0 ||
																tier_detail.rate === '' || tier_detail.rate === null || parseFloat(tier_detail.rate) === 0"
															@click="saveEditRowItems">
															<i class="material-icons material-symbols-outlined">save</i>
														</material-button>
														<material-button color="danger" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnClose"
															@click="cancelEditRowItems">
															<i
																class="material-icons material-symbols-outlined">close</i>
														</material-button>
													</td>
													<td v-if="j.uid === '0'"
														class="text-sm font-weight-normal text-end">
														<InputNumber v-model="j.startValue" autocomplete="off"
															@focus="$event.target.select()" disabled />
													</td>
													<td v-if="j.uid === '0'"
														class="text-sm font-weight-normal text-end">
														<InputNumber v-model="j.endValue" autocomplete="off"
															@focus="$event.target.select()" />
													</td>
													<td v-if="j.uid === '0'"
														class="text-sm font-weight-normal text-end">
														<InputNumber v-model="j.rate" :minFractionDigits="0"
															:maxFractionDigits="2" autocomplete="off"
															@focus="$event.target.select()" />
													</td>
													<td v-if="j.uid === '0'" class="text-sm font-weight-normal">
														<material-button color="primary" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="intrate"
															v-if="set1 + 1 === row.tiers.length && row.intStatus === 'DRAFT'"
															:disabled="j.startValue === '' || j.startValue === null || j.startValue <= start_limit || parseFloat(j.startValue) === 0 ||
																j.endValue === '' || j.endValue === null || j.endValue <= j.startValue || parseFloat(j.endValue) === 0 ||
																j.rate === '' || j.rate === null || parseFloat(j.rate) === 0 || tier_detail.uid !== ''"
															@click="addNewItemsInterestTier(j)">
															<i
																class="material-icons material-symbols-outlined">playlist_add</i>
														</material-button>

													</td>
													<td class="text-sm font-weight-normal"></td>
													<td class="text-sm font-weight-normal"></td>
												</tr>
												<!-- <tr v-if="datas.addNew && row.uid === tier_detail.intUid">
													<td class="text-sm font-weight-normal"></td>
													<td class="text-sm font-weight-normal"></td>
													<td class="text-sm font-weight-normal"></td>
													<td class="text-sm font-weight-normal"></td>
													<td class="text-sm font-weight-normal text-end">
														<InputNumber v-model="tier_detail.startValue" autocomplete="off"
															@focus="$event.target.select()" disabled />
													</td>
													<td class="text-sm font-weight-normal text-end">
														<InputNumber v-model="tier_detail.endValue" autocomplete="off"
															@focus="$event.target.select()" />
													</td>
													<td class="text-sm font-weight-normal text-end">
														<InputNumber v-model="tier_detail.rate" :minFractionDigits="0"
															:maxFractionDigits="2" autocomplete="off"
															@focus="$event.target.select()" />
													</td>
													<td class="text-sm font-weight-normal">
														<material-button color="danger" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnClose"
															@click="datas.addNew = false">
															<i
																class="material-icons material-symbols-outlined">close</i>
														</material-button>
													</td>
												</tr> -->
												<tr v-if="listinterest.length === 0">
													<td colspan="7" class="text-sm font-weight-normal">
														<p style="text-align: center">No Record Found</p>
													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td colspan="11" style="text-align: right">Total Rows : {{
														totalRows_sI }}</td>
												</tr>
											</tfoot>

										</table>
									</div>
								</div>
								<pre></pre>
								<div style="display: flex; justify-content: flex-end">
									<ul v-show="listinterest.length > 0" class="">
										<div class="project-container">
											<div class="example-one">
												<vue-awesome-paginate v-model="currentPageI" :total-items="totalRowsI"
													:items-per-page="perPageI" @click="onClickHandlerI" />
											</div>
										</div>
									</ul>
								</div>
							</div>

						</div>

					</div>

					<div id="promoTab" class="tab-pane fade" role="tabpanel" aria-labelledby="promo-tab">
						<div class="card h-100 mt-2">
							<div class="card-header d-flex justify-content-between align-items-center mb-0">
								<h5 style="display: inline-block;">{{ $t("content.promo") }}</h5>
								<material-button color="primary" variant="outline" class="btn ms-1"
									data-bs-toggle="modal" data-bs-target="#newPromoListModal" id="newPromo"
									v-show="false">

								</material-button>
								<material-button color="primary" variant="outline" class="btn ms-1"
									@click="addItemPromotion">
									<i class="material-icons material-symbols-outlined">add</i>
									{{ add }}
								</material-button>
							</div>
							<div class="card-body pt-0">
								<div class="row mt-1">
									<div class="col-lg-3">
										<label class="form-label ms-0">{{ $t("content.amninterest") }}</label>
										<select class="form-select" v-model="search_intp">
											<option value="">{{ $t('content.optall') }}</option>
											<option v-for="(item, index) in listinterestP" :value="item.uid"
												:key="index">
												{{ item.intName }}
											</option>
										</select>
									</div>

									<div class="col-lg-3">
										<label class="form-label ms-0">{{ $t('content.amntypecustomer')
											}}</label>
										<select class="form-select" v-model="search_custp">
											<option value="">{{ $t('content.optall') }}</option>
											<option v-for="(item, index) in listcustype" :value="item.uid" :key="index">
												{{ item.name }}
											</option>
										</select>
									</div>

									<div class="col-lg-3">
										<label class="form-label ms-0"> {{ $t("content.amnpastsearch") }}</label>
										<select class="form-select" v-model="selectm_p">
											<option value="0" disabled selected>{{ $t('content.select') }}</option>
											<option v-for="i in 12" :key="i" :value=i>
												{{ i }} {{ $t('content.months') }}
											</option>
										</select>
									</div>

									<div class="col-lg-2">
										<label class="form-label ms-0 png"></label>
										<material-button color="primary" class="float-lg-start float-sm-end mb-3"
											@click="loadPromotionListApi">
											<i class="material-icons material-symbols-outlined">search</i> {{ btnSearch
											}}
										</material-button>
									</div>
								</div>
								<div class="row">
									<div class="table-responsive">
										<table class="table table-flush">
											<thead class="thead-light">
												<tr>
													<th>#</th>
													<th>{{ $t("content.listname") }}</th>
													<th>{{ $t("content.cgrade") }}</th>
													<th>{{ $t("content.detail") }}</th>
													<th>{{ $t("content.sdate") }}</th>
													<th>{{ $t("content.edate") }}</th>
													<th>{{ $t("content.period") }}</th>
													<th>{{ $t("content.status") }}</th>
													<th>{{ $t("content.act") }}</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(row, index) in listpromotion" :key="index">
													<td class="text-sm font-weight-normal">
														{{ (currentPageP - 1) * perPageP + index + 1 }}
													</td>
													<td class=" text-sm font-weight-normal">
														{{ checkInterest(row.intUid) }}</td>
													<td class=" text-sm font-weight-normal">
														{{ checkCustype(row.cusTypeUid) }}</td>
													<td class=" text-sm font-weight-normal">
														{{ row.description }}</td>
													<td class=" text-sm font-weight-normal">
														{{ format_date(row.startDate) }}</td>
													<td class=" text-sm font-weight-normal">
														{{ format_date(row.endDate) }}</td>
													<td class=" text-sm font-weight-normal">
														{{ row.duration }}</td>
													<td class=" text-sm font-weight-normal">
														{{ row.intStatus }}</td>
													<td class="text-sm font-weight-normal">
														<material-button color="danger" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="terminate"
															@click="updateItemsPromotion(row, 'terminate')"
															:disabled="row.intStatus !== 'APPROVE' || new Date(row.endDate) < new Date()">
															<i
																class=" material-icons material-symbols-outlined">close</i>
														</material-button>
														<material-button color="warning" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="approve + '/' + revert"
															:disabled="(row.intStatus !== 'APPROVE' && row.intStatus !== 'DRAFT') || new Date(row.endDate) < new Date()"
															@click="updateItemsPromotion(row, 'approve')">
															<i
																class="material-icons material-symbols-outlined">done_all</i>
														</material-button>
														<material-button color="primary" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnEdit"
															@click="editItemPromotion(row)"
															:disabled="row.intStatus === 'APPROVE'">
															<i class="material-icons material-symbols-outlined">edit</i>
														</material-button>
														<material-button color="danger" variant="outline"
															class="rounded-circle" data-bs-toggle="tooltip"
															data-bs-placement="top" :title="btnDel"
															@click="deleteRowPromotionItems(row)"
															:disabled="row.intStatus === 'APPROVE'">
															<i
																class="material-icons material-symbols-outlined">delete</i>
														</material-button>
													</td>
												</tr>
												<tr v-if="listpromotion.length === 0">
													<td colspan="7" class="text-sm font-weight-normal">
														<p style="text-align: center">No Record Found</p>
													</td>
												</tr>
											</tbody>
											<tfoot>
												<tr>
													<td colspan="9" style="text-align: right">Total Rows : {{
														totalRows_sP }}</td>
												</tr>
											</tfoot>
										</table>

									</div>
								</div>
								<pre></pre>
								<div style="display: flex; justify-content: flex-end">
									<ul v-show="listpromotion.length > 0" class="pagination justify-content-end">
										<div class="project-container">
											<div class="example-one">
												<vue-awesome-paginate v-model="currentPageP" :total-items="totalRowsP"
													:items-per-page="perPageP" @click="onClickHandlerP" />
											</div>
										</div>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Add New List Dialog -->
				<div id="newInterestListModal" class="modal fade" tabindex="-1"
					aria-labelledby="newInterestListModalLabel" aria-hidden="true">
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h4 class="modal-title">{{ title_interest }}</h4>
								<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
									<i class="material-icons material-symbols-outlined">close</i>
								</button>
							</div>

							<div class="modal-body">
								<fieldset>
									<div class="row">
										<div class="col-lg-3">
											<label class="form-label ms-0 mb-0 me-3" for="listName">
												{{ $t("content.listname") }}
											</label>
											<material-input id="listName" v-model="interest_detail.intName"
												type="text" />
										</div>

										<div class="col-lg-2">
											<label class="form-label ms-0 mb-0 me-3" for="listType">
												{{ $t("content.inttypes") }}
											</label>
											<select id="listType" class="form-select" v-model="interest_detail.intType">
												<option value="" disabled selected>{{ $t('content.select') }}</option>
												<option v-for="(a, sel1) in forms.listType" :key="sel1" :value="a.val">
													{{ a.items }}
												</option>
											</select>
										</div>

										<div class="col-lg-1">
											<label class="form-label ms-0">
												{{ $t("content.cdefault") }}
											</label>
											<material-checkbox id="listDefault" :checked="interest_detail.defaultInt"
												v-model="interest_detail.defaultInt"
												style="margin-top: 0px !important; text-align: center !important;" />
										</div>


										<div class="col-lg-3">
											<label class="form-label ms-0 mb-0 me-3" for="listStartDate">
												{{ $t("content.sdate") }}
											</label>
											<VueDatePicker v-model="interest_detail.startDate" :format="format"
												auto-apply :teleport="true" :enable-time-picker="false"
												month-name-format="long" :clearable="false"
												:text-input="textInputOptions" fluid />
										</div>

										<div class="col-lg-3">
											<label class="form-label ms-0 mb-0 me-3" for="listEndDate">
												{{ $t("content.edate") }}
											</label>
											<VueDatePicker v-model="interest_detail.endDate" :format="format" auto-apply
												:teleport="true" :enable-time-picker="false" month-name-format="long"
												:clearable="false" :text-input="textInputOptions" fluid />
										</div>

										<div class="col-12">
											<label class="form-label ms-0 mb-0 me-3" for="promoDetail">
												{{ $t("content.detail") }}
											</label>
											<Textarea rows="3" v-model="interest_detail.description" class="w-100"
												autocomplete="off" />
										</div>
									</div>
								</fieldset>
							</div>

							<div class="modal-footer justify-content-between">
								<material-button color="error" variant="contained" class="btn btn-danger"
									data-bs-dismiss="modal">
									{{ cancel }}
								</material-button>
								<div class="d-flex justify-content-end align-items-center">
									<material-button color="primary" variant="contained" class="btn"
										data-bs-dismiss="modal" :disabled="interest_detail.intName.trim() === '' || interest_detail.intType === ''
											|| interest_detail.endDate < interest_detail.startDate" @click="chkProcessInterest">
										{{ save }}
									</material-button>

								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Add New Promo List Dialog -->
				<div id="newPromoListModal" class="modal fade" tabindex="-1" aria-labelledby="newPromoListModalLabel"
					aria-hidden="true">
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h4 class="modal-title">{{ title_promotion }}</h4>
								<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
									<i class="material-icons material-symbols-outlined">close</i>
								</button>
							</div>

							<div class="modal-body">
								<fieldset>
									<div class="row">
										<div class="col-lg-6">
											<label class="form-label ms-0 mb-0 me-3" for="promoName">
												{{ $t("content.listname") }}
											</label>
											<select id="promoType" class="form-select"
												v-model="promotion_detail.intUid">
												<option value="" disabled selected>{{ $t('content.select') }}</option>
												<option v-for="(item, index) in listinterestP" :value="item.uid"
													:key="index">
													{{ item.intName }}
												</option>
												<!-- <option v-for="(a, sel1) in datas.demo1" :key="sel1" :value="a.items">
													{{ a.items }}
												</option> -->
											</select>
										</div>

										<div class="col-lg-6">
											<label class="form-label ms-0 mb-0 me-3">{{ $t('content.amntypecustomer')
												}}</label>
											<select class="form-select" v-model="promotion_detail.cusTypeUid">
												<option value="" disabled selected>{{ $t('content.select') }}</option>
												<option v-for="(item, index) in listcustype" :value="item.uid"
													:key="index">
													{{ item.name }}
												</option>
											</select>
										</div>

										<div class="col-12">
											<label class="form-label ms-0 mb-0 me-3" for="promoDetail">
												{{ $t("content.detail") }}
											</label>
											<Textarea rows="3" v-model="promotion_detail.description" class="w-100"
												autocomplete="off" />
										</div>

										<div class="col-lg-4">
											<label class="form-label ms-0 mb-0 me-3" for="listStartDate">
												{{ $t("content.sdate") }}
											</label>
											<VueDatePicker v-model="promotion_detail.startDate" :format="format"
												auto-apply :teleport="true" :enable-time-picker="false"
												month-name-format="long" :clearable="false"
												:text-input="textInputOptions" fluid />
										</div>

										<div class="col-lg-4">
											<label class="form-label ms-0 mb-0 me-3" for="listEndDate">
												{{ $t("content.edate") }}
											</label>
											<VueDatePicker v-model="promotion_detail.endDate" :format="format"
												auto-apply :teleport="true" :enable-time-picker="false"
												month-name-format="long" :clearable="false"
												:text-input="textInputOptions" fluid />
										</div>

										<div class="col-lg-4">
											<label class="form-label ms-0 mb-0 me-3" for="promoDuration">
												{{ $t("content.period") }} ( {{ $t("content.months") }} )
											</label>
											<InputNumber v-model="promotion_detail.duration" autocomplete="off"
												@focus="$event.target.select()" :max="6" fluid style="height: 2.4rem" />
										</div>


									</div>
								</fieldset>
							</div>

							<div class="modal-footer justify-content-between">
								<material-button color="error" variant="contained" class="btn btn-danger"
									data-bs-dismiss="modal">
									{{ cancel }}
								</material-button>
								<div class="d-flex justify-content-end align-items-center">
									<material-button color="primary" variant="contained" class="btn"
										data-bs-dismiss="modal"
										:disabled="promotion_detail.intUid === '' || promotion_detail.cusTypeUid === '' || promotion_detail.description.trim() === ''
											|| promotion_detail.endDate < promotion_detail.startDate || promotion_detail.duration === '' || promotion_detail.duration === null || parseFloat(promotion_detail.duration) === 0"
										@click="chkProcessPromotion">
										{{ save }}
									</material-button>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import InputNumber from 'primevue/inputnumber';
import RadioButton from 'primevue/radiobutton';
import Textarea from 'primevue/textarea';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import ExampleFive from "@/examples/ExampleOne";
import { reactive, computed, onMounted, ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import * as Choices from "choices.js";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import moment from 'moment'
import axios from "axios";
import * as st from "@/config/setting.js";

import { useInterestStore } from '@/stores/interest'
import { usePromotiontStore } from '@/stores/promotion'
import { useInteresTiertStore } from '@/stores/interesttier'
import { useCusTypeStore } from '@/stores/custype'


const storei = useInterestStore()
const storep = usePromotiontStore()
const storeit = useInteresTiertStore()
const storect = useCusTypeStore()

const shop_detail = ref({})
const interest_detail = ref({
	uid: '',
	shopUid: '',
	intName: '',
	description: '',
	intType: '',
	defaultInt: false,
	startDate: new Date(),
	endDate: new Date(),
	tiers: [],
	intStatus: '',
	version: '',
})
const promotion_detail = ref({
	uid: '',
	shopUid: '',
	intUid: '',
	cusTypeUid: '',
	description: '',
	startDate: new Date(),
	endDate: new Date(),
	duration: '',
	intStatus: '',
	version: '',
})
const tier_detail = ref({
	uid: '',
	intUid: '',
	shopUid: '',
	startValue: '',
	endValue: '',
	rate: '',
	version: '',
})
// const key = JSON.parse(localStorage.getItem("shop_seleted"));


const { t } = useI18n();
const swal = inject('$swal')

const fmNumber = (val) => {
	let num = Number(val).toFixed(2)
	return formatNumberString(num)
	// return Intl.NumberFormat("en-US");
};



const forms = reactive({
	listName: "",
	listType: [
		{ items: computed(() => t("content.normal")), val: 'NORMAL' },
		{ items: computed(() => t("content.special")), val: 'SPECIAL' },
	],
	listStatus: [
		{ val: 'DRAFT' },
		{ val: 'APPROVE' },
	],
	// listIntType: [
	// 	{ items: computed(() => t("content.normal")), val: 'NORMAL' },
	// 	{ items: computed(() => t("content.special")), val: 'SPECIAL' },
	// ],
	listDefault: false,
	listStartPrice: fmNumber(50000000.00),
	listEndedPrice: fmNumber(99999999.99),
	listStartDate: "",
	listEndDate: "",
	listInterest: fmNumber(1.99),
})

const datas = reactive({
	detail: "",
	startDate: "",
	endDate: "",
	duration: "",
	demo1: [
		{ items: "Default" },
		{ items: "VVIP" },
	],
	demo2: [
		{ items: "GENERAL" },
		{ items: "MEMBER" },
		{ items: "SERENADE" },
		{ items: "VVIP" },
	],
	demo3: [
		{ items: computed(() => t("content.amngold")) },
	],
});

const add = computed(() => t("buttons.addd"));
// const confirm = computed(() => t("buttons.conf"));
const intrate = computed(() => t("content.createintrate"));
const btnDel = computed(() => t("buttons.dele"));
const btnEdit = computed(() => t("buttons.edit"))
const cancel = computed(() => t("buttons.canc"));
// const preview = computed(() => t("content.file"));
const save = computed(() => t("buttons.save"));
const approve = computed(() => t("buttons.approve"));
const revert = computed(() => t("buttons.revert"));
const terminate = computed(() => t("buttons.terminate"));
const btnSearch = computed(() => t("buttons.sear"))


const listinterest = ref([]);
const listpromotion = ref([]);
const listinteresttier = ref([]);
const listcustype = ref([]);
const listinterestP = ref([]);

const currentPageI = ref(1);
const totalRowsI = ref(1);
const perPageI = ref(10);
const totalRows_sI = ref();

const currentPageP = ref(1);
const totalRowsP = ref(1);
const perPageP = ref(10);
const totalRows_sP = ref();

const search_int = ref('');
const search_status = ref('');
const search_duration = ref('');

const search_intp = ref('');
const search_custp = ref('');
const search_startD = ref(new Date());
const search_endD = ref(new Date());
const start_limit = ref(0);


const title_interest = ref('');
const process_interest = ref('');

const title_promotion = ref('');
const process_promotion = ref('');

const search_start = ref(new Date());
const search_end = ref(new Date());

const selectm_i = ref('0');
const selectm_p = ref('0');


const format = (date) => {
	return moment(String(date)).local().format('DD/MM/YYYY');
}

const textInputOptions = {
	format: 'dd/MM/yyyy'
};

const onClickHandlerI = (page) => {
	// console.log('onClickHandler :' + page);
	updatePaginationInterestApi()
};

const onClickHandlerP = (page) => {
	// console.log('onClickHandler :' + page);
	updatePaginationPromotionApi()
};

const loadCusTypeListApi = async (val) => {
	let data = "";
	data = {
		index: 0,
		size: 100,
		name: "",
		shopUid: shop_detail.value.uid
	};
	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storect.custypeListFetch(payload);
	// console.log("loadCashAccDataApi : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				listcustype.value = response.data.data.list;
			}
		}
	}
}


const loadDataShop = async () => {
	if (localStorage.getItem("shop_seleted") !== null) {
		shop_detail.value = JSON.parse(localStorage.getItem("shop_seleted"));
		if (shop_detail.value !== null) {
			loadCusTypeListApi();
		}
	}
};

loadDataShop();


const getInterestListApi = async (val) => {
	var d = new Date();
	d.setDate(d.getDate() - 1);
	let data = "";
	data = {
		index: 0,
		size: 100,
		shopUid: shop_detail.value.uid,
		intType: 'SPECIAL',
		intStatus: 'APPROVE',
		startDate: '',
		endDate: '',

	};
	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storei.interestListFetch(payload);
	// console.log("getInterestListApiP : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				listinterestP.value = response.data.data.list;

			}
		}
	}
}




const loadInterestListApi = async (val) => {
	if (currentPageI.value !== 1) {
		if (listinterest.value.length === 1 && val === 'del') {
			currentPageI.value = currentPageI.value - 1
		}
	}
	if (search_int.value !== '' || search_status.value !== '' && val !== 'del') {
		currentPageI.value = 1
	}
	var d = new Date();
	d.setMonth(d.getMonth() - Number(selectm_i.value));
	let data = "";
	data = {
		index: currentPageI.value - 1,
		size: perPageI.value,
		shopUid: shop_detail.value.uid,
		intType: search_int.value,
		intStatus: search_status.value,
		startDate: moment(new Date(d)).local().format('YYYY-MM-DD'),
		endDate: '',
	};
	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storei.interestListFetch(payload);
	// console.log("loadInterestListApi : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				// listinterest.value = response.data.data.list

				// console.log("loadInterestListApi : " + JSON.stringify(listinterest.value));
				totalRowsI.value = response.data.data.ext.total
				totalRows_sI.value = formatNumberString(totalRowsI.value)
				let list = response.data.data.list;
				let tl = []
				list.forEach(function (obj) {
					obj.tiers = [];
					tl.push(String(st.url_api + "/interest-svc/setting/interest-tier/list/" + obj.uid));
				});

				if (tl.length > 0) {
					let listt = await gettiersList(tl)
					let lf = []
					for (let i = 0; i < list.length; i++) {
						lf = []
						if (listt.length > 0) {
							lf = filterData(list[i].uid, listt)
						}
						// console.log('l  ' + i + " : " + JSON.stringify(list[i]))
						if (lf.length > 0) {
							// console.log('tl  ' + i + " : " + lf.length)
							list[i].tiers = lf
							if (list[i].intStatus === 'DRAFT') {
								// console.log('l2 ' + i + " : " + JSON.stringify(list[i]))
								let max = 1
								if (list[i].tiers.length > 0) {
									max = Math.max(...list[i].tiers.map(o => o.endValue), 0);
									max = Number(max + 1)
								}

								let obj =
								{
									"uid": "0",
									"intUid": list[i].uid,
									"startValue": max,
									"endValue": "999999999",
									"rate": "",
								}
								list[i].tiers.push(obj)

							}
						} else {
							if (list[i].intStatus === 'DRAFT') {

								let max = 1
								let obj =
								{
									"uid": "0",
									"intUid": list[i].uid,
									"startValue": max,
									"endValue": "999999999",
									"rate": "",
								}
								list[i].tiers.push(obj)
							}
						}
					}
				}

				listinterest.value = list

				// console.log("loadInterestListApi : " + JSON.stringify(listinterest.value));

			}
		}
	}
}

// loadInterestListApi();

const loadPromotionListApi = async (val) => {
	if (currentPageP.value !== 1) {
		if (listpromotion.value.length === 1 && val === 'del') {
			currentPageP.value = currentPageP.value - 1
		}
	}

	if (search_intp.value !== '' || search_custp.value !== '' && val !== 'del') {
		currentPageP.value = 1
	}
	var d = new Date();
	d.setMonth(d.getMonth() - Number(selectm_p.value));
	let data = "";
	data = {
		index: currentPageP.value - 1,
		size: perPageP.value,
		shopUid: shop_detail.value.uid,
		intUid: search_intp.value,
		cusTypeUid: search_custp.value,
		startDate: moment(new Date(d)).local().format('YYYY-MM-DD'),
		endDate: '',
	};
	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storep.promotionListFetch(payload);
	// console.log("loadPromotionListApi : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				listpromotion.value = response.data.data.list;
				totalRowsP.value = response.data.data.ext.total
				totalRows_sP.value = formatNumberString(totalRowsP.value)
			}

		}

	}
}

const loadInterestTierApi = async (item) => {
	listinteresttier.value = []
	let data = "";
	data = {
		uid: item.intUid,
	};
	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storeit.interestTierListByIntUidFetch(payload);
	// console.log("loadInterestTierApi : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				listinteresttier.value = response.data.data;
				let index = listinterest.value.findIndex((c) => c.uid === item.intUid);
				// console.log("index : " + index);
				let max = 1
				if (listinteresttier.value.length > 0) {
					max = Math.max(...listinteresttier.value.map(o => o.endValue), 0);
					max = Number(max + 1)
				}
				let obj =
				{
					"uid": "0",
					"intUid": item.intUid,
					"startValue": max,
					"endValue": "999999999",
					"rate": "",
				}
				listinteresttier.value.push(obj)
				listinterest.value[index].tiers = listinteresttier.value

			}
		}
	}
}

const updatePaginationInterestApi = async (val) => {
	listinterest.value = []
	var d = new Date();
	d.setMonth(d.getMonth() - Number(selectm_i.value));
	let data = "";
	data = {
		index: currentPageI.value - 1,
		size: perPageI.value,
		shopUid: shop_detail.value.uid,
		intType: search_int.value,
		intStatus: search_status.value,
		startDate: moment(new Date(d)).local().format('YYYY-MM-DD'),
		endDate: '',
	};
	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storei.interestListFetch(payload);
	// console.log("loadInterestListApi : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				let list = response.data.data.list;
				let tl = []
				list.forEach(function (obj) {
					obj.tiers = [];
					tl.push(String(st.url_api + "/interest-svc/setting/interest-tier/list/" + obj.uid));
				});

				if (tl.length > 0) {
					let listt = await gettiersList(tl)
					let lf = []
					for (let i = 0; i < list.length; i++) {
						lf = []
						if (listt.length > 0) {
							lf = filterData(list[i].uid, listt)
						}
						if (lf.length > 0) {
							// console.log('tl  ' + i + " : " + lf.length)
							list[i].tiers = lf
							if (list[i].intStatus === 'DRAFT') {
								// console.log('l2 ' + i + " : " + JSON.stringify(list[i]))
								let max = 1
								if (list[i].tiers.length > 0) {
									max = Math.max(...list[i].tiers.map(o => o.endValue), 0);
									max = Number(max + 1)
								}

								let obj =
								{
									"uid": "0",
									"intUid": list[i].uid,
									"startValue": max,
									"endValue": "999999999",
									"rate": "",
								}
								list[i].tiers.push(obj)

							}
						} else {
							if (list[i].intStatus === 'DRAFT') {

								let max = 1
								let obj =
								{
									"uid": "0",
									"intUid": list[i].uid,
									"startValue": max,
									"endValue": "999999999",
									"rate": "",
								}
								list[i].tiers.push(obj)
							}
						}
					}
				}
				listinterest.value = list
				// console.log("loadInterestListApi : " + JSON.stringify(listinterest.value));
				totalRowsI.value = response.data.data.ext.total
				totalRows_sI.value = formatNumberString(totalRowsI.value)

			}
		}
	}
}

const updatePaginationPromotionApi = async (val) => {
	listpromotion.value = []
	var d = new Date();
	d.setMonth(d.getMonth() - Number(selectm_p.value));
	let data = "";
	data = {
		index: currentPageP.value - 1,
		size: perPageP.value,
		shopUid: shop_detail.value.uid,
		intUid: search_intp.value,
		cusTypeUid: search_custp.value,
		startDate: moment(new Date(d)).local().format('YYYY-MM-DD'),
		endDate: '',
	};
	const payload = data;
	// console.log("payload : " + JSON.stringify(payload));
	const response = await storep.promotionListFetch(payload);
	// console.log("loadPromotionListApi : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				listpromotion.value = response.data.data.list;
				totalRowsP.value = response.data.data.ext.total
				totalRows_sP.value = formatNumberString(totalRowsP.value)
			}

		}

	}
}


async function onClickTab(val) {
	if (val === '1') {
		await loadInterestListApi();
	} else if (val === '2') {
		await getInterestListApi()
		await loadPromotionListApi();
	}
}

function formatNumberString(num) {
	return String(num).replace(
		/(\d)(?=(\d{3})+(?!\d))/g,
		"$1,"
	);
}

function numberWithCommas(x) {
	return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}



function format_date(value) {
	if (value) {
		return moment(String(value)).local().format('DD/MM/YYYY')
	}
}



function filterData(str, matchArr) {
	return matchArr.filter((c) => c.intUid === str);
}

function clearItemInterest() {
	datas.addNew = false
	interest_detail.value = {
		uid: '',
		shopUid: '',
		intName: '',
		description: '',
		intType: '',
		defaultInt: false,
		startDate: new Date(),
		endDate: new Date(),
		tiers: [],
		intStatus: '',
		version: '',
	}

}

function addItemInterest() {
	clearItemInterest()
	title_interest.value = t("content.addinterest")
	process_interest.value = 'add'
	document.getElementById('newInterest').click()
}


function editItemInterest(item) {
	clearItemInterest()
	interest_detail.value = {
		uid: item.uid,
		shopUid: item.shopUid,
		intName: item.intName,
		description: item.description,
		intType: item.intType,
		defaultInt: item.defaultInt,
		startDate: new Date(item.startDate),
		endDate: new Date(item.endDate),
		tiers: [],
		intStatus: item.intStatus,
		version: item.version,
	}
	title_interest.value = t("content.editinterest")
	process_interest.value = 'edit'
	document.getElementById('newInterest').click()
}

function chkProcessInterest() {
	if (process_interest.value === 'add') {
		addInterest()
	} else if (process_interest.value === 'edit') {
		editInterest()
	}
}

async function saveEditRowItems() {
	swal({
		title: "บันทึกแก้ไขรายการ",
		text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
		icon: "info",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t('buttons.save'),
		cancelButtonText: t('buttons.canc'),
	}).then((result) => {
		if (result.isConfirmed) {
			// swal("บันทึกสำเร็จ", "", "success")
			datas.rowEdit = false
			if (tier_detail.value.uid !== "" && tier_detail.value.version !== "") {
				updateInterestTier()
			}
		} else if (result.dismiss == 'cancel') {
			swal("ยกเลิกแก้ไข", "", "error")
			datas.rowEdit = false
		}
	})
}


async function cancelEditRowItems() {
	swal({
		title: "ยกเลิกแก้ไขรายการ",
		text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
		icon: "warning",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t('buttons.conf'),
		cancelButtonText: t('buttons.canc'),
	}).then((result) => {
		if (result.isConfirmed) {
			datas.rowEdit = false
			cancelInterestTier()
		} else if (result.dismiss == 'cancel') {
			datas.rowEdit = true
		}
	})
}

function cancelInterestTier() {
	tier_detail.value = {
		uid: '',
		intUid: '',
		shopUid: '',
		startValue: '',
		endValue: '',
		rate: '',
		version: '',
	}
}

function clearItemPromotion() {
	promotion_detail.value = {
		uid: '',
		shopUid: '',
		intUid: '',
		cusTypeUid: '',
		description: '',
		startDate: new Date(),
		endDate: new Date(),
		duration: '',
		intStatus: '',
		version: '',
	}

}


function addItemPromotion() {
	clearItemPromotion()
	title_promotion.value = t("content.addpromo")
	process_promotion.value = 'add'
	document.getElementById('newPromo').click()
}

function editItemPromotion(item) {
	clearItemPromotion()
	promotion_detail.value = {
		uid: item.uid,
		intUid: item.intUid,
		cusTypeUid: item.cusTypeUid,
		description: item.description,
		startDate: new Date(item.startDate),
		endDate: new Date(item.endDate),
		duration: item.duration,
		intStatus: item.intStatus,
		version: item.version,
	}
	promotion_detail.value.duration = promotion_detail.value.duration.replace(/[^\d]/g, '');
	title_promotion.value = t("content.editpromo")
	process_promotion.value = 'edit'
	document.getElementById('newPromo').click()
}

function chkProcessPromotion() {
	if (process_promotion.value === 'add') {
		addPromotion()
	} else if (process_promotion.value === 'edit') {
		editPromotion()
	}
}



async function addNewItemsInterest() {
	swal({
		title: "เพิ่มรายการ",
		text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
		icon: "info",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t('buttons.conf'),
		cancelButtonText: t('buttons.canc'),
	}).then((result) => {
		if (result.isConfirmed) {
			// clearItem()
			// datas.addNew = true
		} else if (result.dismiss == 'cancel') {
			swal("ยกเลิกรายการ", "", "error")
			datas.addNew = false
		}
	})
}

async function addNewItemsPromotion() {
	swal({
		title: "เพิ่มรายการ",
		text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
		icon: "info",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t('buttons.conf'),
		cancelButtonText: t('buttons.canc'),
	}).then((result) => {
		if (result.isConfirmed) {
			// clearItem()
			// datas.addNew = true
		} else if (result.dismiss == 'cancel') {
			swal("ยกเลิกรายการ", "", "error")
			datas.addNew = false
		}
	})
}

async function addNewItemsInterestTier(item) {
	swal({
		title: "เพิ่มรายการ",
		text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
		icon: "info",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t('buttons.conf'),
		cancelButtonText: t('buttons.canc'),
	}).then((result) => {
		if (result.isConfirmed) {
			// clearItem()
			addInterestTier(item)
		} else if (result.dismiss == 'cancel') {
			swal("ยกเลิกรายการ", "", "error")
			datas.addNew = false
		}
	})
}


async function updateItemsInterest(item, val) {
	swal({
		title: "แก้ไขรายการ",
		text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
		icon: "info",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t('buttons.conf'),
		cancelButtonText: t('buttons.canc'),
	}).then((result) => {
		if (result.isConfirmed) {
			if (item.uid !== "") {
				console.log("update i : " + JSON.stringify(item));
				updateInterest(item, val)
			}
		} else if (result.dismiss == 'cancel') {
			swal("ยกเลิกรายการ", "", "error")
			datas.rowEdit = false
		}
	})
}

async function updateItemsPromotion(item, val) {
	swal({
		title: "แก้ไขรายการ",
		text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
		icon: "info",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t('buttons.conf'),
		cancelButtonText: t('buttons.canc'),
	}).then((result) => {
		if (result.isConfirmed) {
			if (item.uid !== "") {
				console.log("update p : " + JSON.stringify(item));
				updatePromotion(item, val)
			}
		} else if (result.dismiss == 'cancel') {
			swal("ยกเลิกรายการ", "", "error")
		}
	})
}

async function editRowItems(item) {
	swal({
		title: "แก้ไขรายการ",
		text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
		icon: "info",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t('buttons.conf'),
		cancelButtonText: t('buttons.canc'),
	}).then((result) => {
		if (result.isConfirmed) {
			tier_detail.value = {
				uid: item.uid,
				intUid: item.intUid,
				shopUid: item.shopUid,
				startValue: item.startValue,
				endValue: item.endValue,
				rate: item.rate,
				version: item.version,
			}
			datas.addNew = false
		} else if (result.dismiss == 'cancel') {
			swal("ยกเลิกรายการ", "", "error")
			datas.rowEdit = false
		}
	})
}

async function deleteRowItems(item) {
	swal({
		title: "ลบรายการ",
		text: "คุณต้องการลบรายการนี้ใช่ไหม",
		icon: "warning",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t("buttons.conf"),
		cancelButtonText: t("buttons.canc"),
	}).then((result) => {
		if (result.isConfirmed) {
			if (item.uid !== "") {
				deleteInterestTier(item.uid)
			}
		}
	});
}

async function deleteRowInterestItems(item) {
	swal({
		title: "ลบรายการ",
		text: "คุณต้องการลบรายการนี้ใช่ไหม",
		icon: "warning",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t("buttons.conf"),
		cancelButtonText: t("buttons.canc"),
	}).then((result) => {
		if (result.isConfirmed) {
			if (item.uid !== "") {
				deleteInterest(item.uid)
			}
		}
	});
}

async function deleteRowPromotionItems(item) {
	swal({
		title: "ลบรายการ",
		text: "คุณต้องการลบรายการนี้ใช่ไหม",
		icon: "warning",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t("buttons.conf"),
		cancelButtonText: t("buttons.canc"),
	}).then((result) => {
		if (result.isConfirmed) {
			if (item.uid !== "") {
				deletePromotion(item.uid)
			}
		}
	});
}

function checkCustype(str) {
	let side = ''
	if (str !== '0') {
		let ld = listcustype.value.filter((c) => c.uid === str);
		if (ld.length > 0) {
			side = ld[0].name
		}
	}
	return side
}

function checkInterest(str) {
	let side = ''
	if (str !== '0') {
		let ld = listinterestP.value.filter((c) => c.uid === str);
		if (ld.length > 0) {
			side = ld[0].intName
		}
	}
	return side
}

function checkIntType(str) {
	let side = ''
	if (str !== '0') {
		let ld = forms.listType.filter((c) => c.val === str);
		if (ld.length > 0) {
			side = ld[0].items
		}
	}
	return side
}


async function addInterest() {
	const payload = interest_detail.value;
	payload.shopUid = shop_detail.value.uid
	payload.startDate = moment(new Date(payload.startDate)).local().format('YYYY-MM-DD')
	payload.endDate = moment(new Date(payload.endDate)).local().format('YYYY-MM-DD')
	// console.log("addInterest p : " + JSON.stringify(payload));
	const response = await storei.interestAddFetch(payload);
	// console.log("addInterest r : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				swal("บันทึกสำเร็จ", "", "success");
				loadInterestListApi('add')
			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}
}

async function editInterest() {
	const payload = interest_detail.value;
	payload.shopUid = shop_detail.value.uid
	payload.startDate = moment(new Date(payload.startDate)).local().format('YYYY-MM-DD')
	payload.endDate = moment(new Date(payload.endDate)).local().format('YYYY-MM-DD')
	// console.log("addInterest p : " + JSON.stringify(payload));
	const response = await storei.interestUpdateFetch(payload);
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				swal("บันทึกสำเร็จ", "", "success");
				loadInterestListApi('add')
			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}


}

async function addPromotion() {
	const payload = promotion_detail.value;
	payload.shopUid = shop_detail.value.uid
	payload.startDate = moment(new Date(payload.startDate)).local().format('YYYY-MM-DD')
	payload.endDate = moment(new Date(payload.endDate)).local().format('YYYY-MM-DD')
	payload.duration = String(payload.duration) + 'm'
	console.log("addPromotion p : " + JSON.stringify(payload));
	const response = await storep.promotionAddFetch(payload);
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				await loadPromotionListApi('add')
				swal("บันทึกสำเร็จ", "", "success");
			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}
}


async function editPromotion() {
	const payload = promotion_detail.value;
	payload.shopUid = shop_detail.value.uid
	payload.startDate = moment(new Date(payload.startDate)).local().format('YYYY-MM-DD')
	payload.endDate = moment(new Date(payload.endDate)).local().format('YYYY-MM-DD')
	payload.duration = String(payload.duration) + 'm'
	// console.log("editPromotion p : " + JSON.stringify(payload));
	const response = await storep.promotionUpdateFetch(payload);
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				await loadPromotionListApi('edit')
				swal("บันทึกสำเร็จ", "", "success");

			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}
}

async function addInterestTier(item) {
	const payload = item;
	payload.shopUid = shop_detail.value.uid
	payload.uid = ""
	payload.startValue = String(payload.startValue)
	payload.endValue = String(payload.endValue)
	payload.rate = String(payload.rate)
	// console.log("addInterestTier p : " + JSON.stringify(payload));
	const response = await storeit.interestTierAddFetch(payload);
	console.log("addInterestTier r : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				swal("บันทึกสำเร็จ", "", "success");
				loadInterestTierApi(item)
			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}


}

async function updateInterest(item, val) {
	let data = "";
	data = {
		uid: item.uid,
	};
	let response = null
	const payload = data;
	// console.log("updateInterest v : " + val);
	if (val === 'terminate') {
		response = await storei.interestRevokeFetch(payload);
	} else if (val === 'approve') {
		if (item.intStatus === 'DRAFT') {
			response = await storei.interestApproveFetch(payload);
		} else if (item.intStatus === 'APPROVE') {
			response = await storei.interestRevertFetch(payload);
		}

	}

	if (response) {
		if (response.status === 200) {
			console.log("updateInterest r : " + JSON.stringify(response.data));
			if (response.data.rtnDesc === "success") {
				swal("บันทึกสำเร็จ", "", "success");
				loadInterestListApi('update')
			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}

}

async function updatePromotion(item, val) {
	let data = "";
	data = {
		uid: item.uid,
	};
	let response = null
	const payload = data;
	if (val === 'terminate') {
		response = await storep.promotionRevokeFetch(payload);
	} else if (val === 'approve') {
		if (item.intStatus === 'DRAFT') {
			response = await storep.promotionApproveFetch(payload);
		} else if (item.intStatus === 'APPROVE') {
			response = await storep.promotionRevertFetch(payload);
		}

	}
	if (response) {
		if (response.status === 200) {
			// console.log("updatePromotion r : " + JSON.stringify(response.data));
			if (response.data.rtnDesc === "success") {
				swal("บันทึกสำเร็จ", "", "success");
				loadPromotionListApi('update')
			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}
}

async function updateInterestTier() {
	const payload = tier_detail.value;
	payload.startValue = String(payload.startValue)
	payload.endValue = String(payload.endValue)
	payload.rate = String(payload.rate)

	// console.log("updateInterestTier p : " + JSON.stringify(payload));
	const response = await storeit.interestTierUpdateFetch(payload);
	// console.log("updateInterestTier r : " + JSON.stringify(response.data));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				swal("บันทึกสำเร็จ", "", "success");
				loadInterestTierApi(tier_detail.value)
				tier_detail.value = {
					uid: '',
					intUid: '',
					shopUid: '',
					startValue: '',
					endValue: '',
					rate: '',
					version: '',
				}

			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}
}

async function deleteInterestTier(id) {
	let data = "";
	data = {
		uid: id,
	};
	const payload = data;
	// const response = await store.dispatch("unitDeleteFetch", payload);
	const response = await storeit.interestTierDeleteFetch(payload);
	// console.log('loadDataApi : '+JSON.stringify(response));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				swal("บันทึกสำเร็จ", "", "success");
				loadInterestListApi('update')
			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}

}

async function deleteInterest(id) {
	let data = "";
	data = {
		uid: id,
	};
	const payload = data;
	// const response = await store.dispatch("unitDeleteFetch", payload);
	const response = await storei.interestDeleteFetch(payload);
	// console.log('loadDataApi : '+JSON.stringify(response));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				swal("บันทึกสำเร็จ", "", "success");
				loadInterestListApi('del')
			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}

}


async function deletePromotion(id) {
	let data = "";
	data = {
		uid: id,
	};
	const payload = data;
	// const response = await store.dispatch("unitDeleteFetch", payload);
	const response = await storep.promotionDeleteFetch(payload)
	// console.log('loadDataApi : '+JSON.stringify(response));
	if (response) {
		if (response.status === 200) {
			if (response.data.rtnDesc === "success") {
				swal("บันทึกสำเร็จ", "", "success");
				loadPromotionListApi('del')
			} else {
				swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
			}
		}
	}

}


async function gettiersList(urls) {
	let listd = []
	const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-admin"));
	const accessToken = key.accessToken;
	axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
	const requests = urls.map((url) => axios.get(url));
	await Promise.all(requests).then(allResults => {
		if (allResults) {
			if (allResults !== null) {
				// console.log('gettiersList : ' + JSON.stringify(allResults));
				for (let i = 0; i < allResults.length; i++) {
					if (allResults[i].data !== null) {
						// console.log('gettiersList : ' + allResults[i].data.data.length);
						for (let j = 0; j < allResults[i].data.data.length; j++) {
							listd.push(allResults[i].data.data[j])
						}
					}
				}
				// console.log('gettiersList xxx : ' + JSON.stringify(listd));
			}
		}
	})
	return listd
}



onMounted(() => {
	if (document.getElementById("choices-month")) {
		var month = document.getElementById("choices-month");
		setTimeout(function () {
			new Choices(month);
		}, 1);

		// eslint-disable-next-line no-unused-vars
		var d = new Date();
		var monthArray = new Array();
		monthArray[0] = "January";
		monthArray[1] = "February";
		monthArray[2] = "March";
		monthArray[3] = "April";
		monthArray[4] = "May";
		monthArray[5] = "June";
		monthArray[6] = "July";
		monthArray[7] = "August";
		monthArray[8] = "September";
		monthArray[9] = "October";
		monthArray[10] = "November";
		monthArray[11] = "December";
		for (let m = 0; m <= 11; m++) {
			var optn = document.createElement("OPTION");
			optn.text = monthArray[m];
			// server side month start from one\
			optn.value = m + 1;
			// if june selected
			if (m == 1) {
				optn.selected = true;
			}
			month.options.add(optn);
		}
	}

	if (document.getElementById("choices-day")) {
		var day = document.getElementById("choices-day");
		setTimeout(function () {
			new Choices(day);
		}, 1);

		for (let y = 1; y <= 31; y++) {
			var optn1 = document.createElement("OPTION");
			optn1.text = y;
			optn1.value = y;

			if (y == 1) {
				optn1.selected = true;
			}

			day.options.add(optn1);
		}
	}

	if (document.getElementById("choices-year")) {
		var year = document.getElementById("choices-year");
		setTimeout(function () {
			new Choices(year);
		}, 1);

		for (let y = 1900; y <= 2020; y++) {
			var optn2 = document.createElement("OPTION");
			optn2.text = y;
			optn2.value = y;

			if (y == 2020) {
				optn.selected = true;
			}

			year.options.add(optn2);
		}
	}
});
</script>

<style lang="scss" scoped></style>